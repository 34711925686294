<template>
  <section class="detail-page-hakka">
    <v-container class="mt-20 pa-md-0 pa-6">
      <p class="text-h3 pb-8 main-title">Hakka TV</p>
      <div
        class="d-flex flex-row flex-wrap justify-md-space-between mt-10 mb-15"
      >
        <p class="mb-md-0 mb-5">
          客家電視台為是一個以【客家】為核心的一個公共媒體，透過每日新聞、節目與戲劇推廣客語與客家文化。
        </p>
        <div class="text-md-end text-start align-self-end">
          <p class="mb-1">WEB DESIGN & OTT</p>
          <p class="mb-0">2021</p>
        </div>
      </div>
    </v-container>
    <div class="mb-10">
      <v-img
        :src="require('@/assets/img/detail/hakka/main.png')"
        class="main-img"
      ></v-img>
    </div>

    <v-container>
      <v-row class="step-card">
        <v-col cols="12" md="6" class="pa-md-10 pa-3">
          <div class="step-card__number">01</div>
          <div class="pa-md-5 pa-3">
            <h1 class="step-card__title">需求與痛點</h1>
            <p class="step-card__content">
              舊網站屬於基本官網，觀眾可點選影音撥放，但未使用OTT服務，且缺少屬於客台的風格，因此本次專案希望建立一個影音隨選隨看，複合每日新聞及官方資訊的「網站與APP」，其中需建置直播自動側錄並上架的系統，解決影音上架人手不足的問題。除此之外，網站也需進行觀眾的數據蒐集，作為相關行銷人員的分析依據，以利日後平台與節目的提升。
            </p>
          </div>
        </v-col>
        <v-col cols="12" md="6" class="pa-md-10 pa-3">
          <v-img
            class="d-block step-card__img img-border-radius"
            src="@/assets/img/detail/hakka/desktop/d2.png"
            width="100%"
          ></v-img>
        </v-col>
      </v-row>

      <v-row class="step-card flex-md-row flex-column-reverse">
        <v-col cols="12" md="6" class="pa-md-10 pa-3">
          <v-img
            class="d-block step-card__img img-border-radius"
            src="@/assets/img/detail/hakka/desktop/d3.png"
            width="100%"
          ></v-img>
        </v-col>
        <v-col cols="12" md="6" class="pa-md-10 pa-3">
          <div class="step-card__number">02</div>
          <div class="pa-md-5 pa-3">
            <h1 class="step-card__title">設計概念</h1>
            <p class="step-card__content">
              此次主視覺風格是由客家電視台的名片衍伸而來，背景使用大色塊與幾何圖形，元件也運用有稜角的框線與圖示，並搭配客台的指標色—亮綠色，呈現乾淨、明亮的感覺<br />
              另外，兒少首頁則是不同風格，以手繪的彩色幾何作為背景，搭配專屬的卡通角色，每一個角色象徵不同地區的客家族群特色，並加入互動小巧思，希望更吸引孩童與青少年目光
              本次也特別設計明亮與暗黑兩主題，可依照需求轉換不同的顏色風格
            </p>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-row>
        <v-col cols="12">
          <div class="d-flex flex-column align-center justify-center">
            <div class="step-card__number">03</div>
            <div class="my-10">
              <h1 class="step-card__title">構思與解決方案</h1>
            </div>
            <div class="my-10 step-card__dot step-card__dot__top">
              網站架構規劃與介面設計
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="6" class="pa-md-10 pa-3 mt-5">
          <div class="pa-md-5 pa-3">
            <h2 class="mb-10 detail-subtitle">WEB 與 APP 兩種網站資訊架構</h2>
            <p class="detail-content">
              根據需求訪談的過程，理解客戶在WEB 與 APP
              各自要呈現的資訊重點並不全然相同，WEB以隨選影音為主軸，新聞、官方資料為副軸；APP則以新聞為主軸，會員、官方資料為副軸，因此並非將WEB所有內容直接搬移至APP呈現，需依照資料類型與型態個別規劃
            </p>
          </div>
          <div class="my-10">
            <v-img
              class="d-block img-border-radius"
              src="@/assets/img/detail/hakka/desktop/d6.png"
              max-width="737"
              max-height="764"
            ></v-img>
          </div>
        </v-col>
        <v-col cols="12" md="6" class="pa-md-10 pa-3 mt-200">
          <div class="pa-md-5 pa-3">
            <h2 class="mb-10 detail-subtitle">策展模組</h2>
            <p class="detail-content">
              避免後續維護過於複雜，我們規劃一種自由編輯頁面的方式—策展模組，如同在頁面中有多個展示窗，依照不同檔期更換主題內容，於後台管理系統中，能以相同的方式編輯WEB與APP介面
            </p>
          </div>
          <div class="my-10">
            <v-img
              class="d-block img-border-radius"
              src="@/assets/img/detail/hakka/desktop/d5.png"
              max-width="737"
              max-height="764"
            ></v-img>
          </div>
        </v-col>
        <v-col cols="12">
          <div class="d-flex flex-column align-center justify-center">
            <div class="my-10 step-card__dot">直播側錄機制規劃</div>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="ideate-user-flow mb-10">
      <div class="mb-10">
        <section ref="ideate-wrap" class="ideate-wrap">
          <div>
            <v-img
              contain
              max-width="1000"
              class="ma-auto img-border-radius"
              :src="require('@/assets/img/detail/hakka/desktop/user-flow.png')"
            />
          </div>
        </section>
      </div>
    </v-container>

    <v-container class="mb-10">
      <v-row>
        <v-col cols="12" md="6" class="pa-md-10 pa-3">
          <div class="pa-md-5 pa-3">
            <h2 class="mb-10 detail-subtitle">直播側錄機制</h2>
            <p class="detail-content">
              為了讓觀眾在非新聞時段也能觀看當日即時新聞，客台會上傳每日各節的新聞影音，但此過程需有額外人力進行及時的錄製、剪輯再上架，因此希望以自動化的方式完成此流程，加快上架時間與減少人力。
              每日的直播新聞自動化的機制是根據提供的節目表，在特定節目時間區間啟動側錄機制，此時會透過直播串流取得影音源，並儲存，提供前台
              user 觀看與 CMS 後台人員操作使用。
            </p>
          </div>
        </v-col>
        <v-col cols="12" md="6" class="pa-md-10 pa-3">
          <div class="pa-md-5 pa-3">
            <h2 class="mb-10 detail-subtitle">Wowza伺服器、EFS儲存影片檔</h2>
            <p class="detail-content">
              OTT服務的困難點在於開發流程的繁雜，影音的檔案大、流量大，在技術上，我們本次選擇wowza作為串流伺服器，將直播影片推流至wowza進行處理，並將影音檔案存於EFS。與存在S3相比，EFS有效降低儲存成本且能夠支持此專案的主機架構，因此其他在非側錄直接上傳的影音檔，上傳至S3後也會透過data
              sync將影音檔案搬移至EFS；當觀眾在網站要觀看影片時，再以m3u8檔讓撥放器撥放，與直接撥放mp4檔相比，m3u8檔能增加惡意存取影音檔時的困難。
            </p>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <section class="mb-2">
      <v-img
        contain
        height="auto"
        :src="require('@/assets/img/detail/hakka/desktop/app.png')"
      ></v-img>
    </section>

    <section class="mb-20">
      <div class="d-flex flex-column flex-md-row">
        <div class="design-block__img mr-md-1 mb-2">
          <v-img
            :src="require('@/assets/img/detail/hakka/desktop/d1.png')"
          ></v-img>
        </div>
        <div class="design-block__img ml-md-1 mb-2">
          <div
            class="
              d-flex
              justify-center
              align-center
              hakka-gifbox hakka-gifbox-green2
            "
          >
            <v-img
              max-width="90%"
              height="auto"
              width="90%"
              cover
              :src="
                require('@/assets/img/detail/hakka/desktop/hakka-child-ip.gif')
              "
            ></v-img>
          </div>
        </div>
      </div>
      <!-- ---------------------- -->
      <div class="d-flex flex-column flex-md-row">
        <div class="design-block__img mr-md-1 mb-2">
          <div
            class="
              d-flex
              justify-center
              align-center
              hakka-gifbox hakka-gifbox-green
            "
          >
            <v-img
              style="padding: 50px 0; margin: auto 30px"
              max-width="745"
              height="auto"
              width="100%"
              contain
              :src="
                require('@/assets/img/detail/hakka/desktop/hakka-child.gif')
              "
            ></v-img>
          </div>
        </div>
        <div class="design-block__img ml-md-1 mb-2">
          <v-img
            :src="require('@/assets/img/detail/hakka/desktop/d4.png')"
          ></v-img>
        </div>
      </div>
      <!-- ---------------------- -->
    </section>

    <!-- Talk -->
    <Talk class="mb-4 mt-3" />
    <!-- footer -->
    <Footer />
  </section>
</template>

<script>
import PlanDesign from "@/components/detail/planDesign.vue";
import BrainStorm from "@/components/detail/brainStorm.vue";
import scrollbar from "@/views/scrollbar.js";
import Talk from "@/components/talk.vue";
import Footer from "@/components/footer.vue";
import ButtonGroup from "@/components/detail/buttonGroup.vue";
export default {
  mixins: [scrollbar],
  components: {
    PlanDesign,
    BrainStorm,
    Talk,
    Footer,
    ButtonGroup,
  },
  data: () => ({
    buttonGroupIndex: 0,
    tools: [
      { src: require("@/assets/img/tool/1.png") },
      { src: require("@/assets/img/tool/2.png") },
      { src: require("@/assets/img/tool/3.png") },
      { src: require("@/assets/img/tool/4.png") },
      { src: require("@/assets/img/tool/6.png") },
      { src: require("@/assets/img/tool/8.png") },
    ],
  }),
  computed: {
    windowWidth() {
      return this.$vuetify.breakpoint.width;
    },
  },
  async mounted() {},
  methods: {},
};
</script>